import { computed, type Ref, ref } from 'vue';

//import jwtDecode from 'jwt-decode';
import { jwtDecode } from "jwt-decode";

import UserService from '@/api/users';
import { useAuthStore } from "@/stores/auth";
import { refreshAccessToken, login as performVortexLogin } from "@/api/auth";
import type { ComputedRef } from 'vue';
import { type Router } from 'vue-router';

export enum AuthBackendEnum {
    NONE = "",
    VORTEX = "VORTEX",
}

type LoginManagerInstance = {
    logout: () => void;
    loginVortex: (username: string, password: string) => Promise<void>;
    isLogged: ComputedRef<boolean>;
    hasPrivilege: (privilege: string) => boolean;
    me: Ref<LoggedUserInfo | null>;
    accessToken: ComputedRef<Promise<string | null>>;
    init: (router: Router) => Promise<void>;
};

type LoggedUserInfo = {
    id: number,
    name: string,
    email: string,
    privileges: string[],
}

export type DecodedJWTType = {
    token_type: "access" | "refresh";
    exp: number;
}

class VortexUserManager {
    refreshTokenInterval: number | null = null;
    readonly safetyTimeMargin = 120;

    async getAccessToken() {
        const authStore = useAuthStore();
        return authStore.accessToken;
    }

    refreshTokenIfNeeded() {
        const authStore = useAuthStore();

        if (authStore.accessToken) {
            const now = Date.now() / 1000
            if (authStore.refreshToken && this.needRefresh(now) && this.canRefresh(now)) {
                refreshAccessToken(authStore.refreshToken).then((newTokens) => {
                    authStore.setAuthTokens(newTokens.access);
                });
            } else if (!this.canRefresh(now)) {
                this.logout();
            }
        }
    }

    startRefreshTokenInterval() {
        if (this.refreshTokenInterval) {
            return
        }
        this.refreshTokenInterval = setInterval(() => this.refreshTokenIfNeeded(), 3000);
        this.refreshTokenIfNeeded();
    }

    stopRefreshTokenInterval() {
        if (this.refreshTokenInterval) {
            console.log("stop refresh token interval", new Date());
            clearInterval(this.refreshTokenInterval);
            this.refreshTokenInterval = null;
        }
    }

    logout() {
        this.stopRefreshTokenInterval();
        const authStore = useAuthStore();
        authStore.setAuthTokens(null, null);
    }

    needRefresh(now: number) {
        const authStore = useAuthStore();
        authStore.accessToken

        if (!authStore.accessToken) {
            return false;
        }
        const jwt = jwtDecode(authStore.accessToken) as DecodedJWTType;
        if (now + this.safetyTimeMargin > jwt.exp) {
            return true;
        }
        return false;
    };

    canRefresh(now: number) {
        const authStore = useAuthStore();

        if (!authStore.refreshToken) {
            return false;
        }
        const jwt = jwtDecode(authStore.refreshToken) as DecodedJWTType;
        if (now + this.safetyTimeMargin > jwt.exp) {
            // refresh token is valid
            return false;
        }
        return true;
    };

    async login(username: string, password: string) {
        const authStore = useAuthStore();
        const tokens = await performVortexLogin(username, password);
        authStore.setAuthTokens(tokens.access, tokens.refresh);
        this.startRefreshTokenInterval();
    }
}

export default (function () {
    const vortexUserManager = new VortexUserManager();
    const isLogged = computed(() => activeUserManager.value != null);
    const accessToken = computed(() => activeUserManager.value?.getAccessToken() || Promise.resolve(null));

    const activeUserManager: Ref<VortexUserManager | null> = ref(null);
    const me: Ref<LoggedUserInfo | null> = ref(null);

    let initialized = false;

    function logout() {
        if (activeUserManager.value) {
            activeUserManager.value.logout();
        } else {
            console.warn("You're not logged in. Unable to logout.")
        }

        forgetMe();
    }

    function forgetMe() {
        me.value = null;
        const authStore = useAuthStore();
        authStore.setAuthBackend(AuthBackendEnum.NONE);
        activeUserManager.value = null;
    }

    async function loadMe() {
        const r = (await UserService.me()).data;
        me.value = <LoggedUserInfo>{
            id: r.id,
            email: r.email,
            name: r.full_name,
            privileges: r.privileges,
        }
    }

    async function loginVortex(username: string, password: string) {
        await vortexUserManager.login(username, password);

        const authStore = useAuthStore();
        activeUserManager.value = vortexUserManager;
        authStore.setAuthBackend(AuthBackendEnum.VORTEX);

        await loadMe();
    }

    function hasPrivilege(privilege: string) {
        const i = me.value?.privileges.indexOf(privilege);
        if (i === undefined) {
            return false;
        }
        return i >= 0;
    }

    async function init(router: Router) {
        if (initialized) {
            return
        }

        const authStore = useAuthStore();

        try {
            if (authStore.authBackend == AuthBackendEnum.VORTEX) {
                activeUserManager.value = vortexUserManager;
                activeUserManager.value.startRefreshTokenInterval();

                await loadMe();
            }

            initialized = true;
        } catch (e: unknown) {
            console.error(e);
            forgetMe();
            router.push({
                "name": "login",
            });
        }
    }

    const instance: LoginManagerInstance = { logout, loginVortex, isLogged, hasPrivilege, me, accessToken, init };

    return () => {
        return instance;
    };
})()