<script setup lang="ts">
const apiUrl = import.meta.env.VITE_API_URL;
// import { getCurrentInstance } from 'vue';
// const vm = getCurrentInstance();
// const x: string = vm?.appContext.config.globalProperties. as string;

import { ref, onMounted } from 'vue';
import AppVersionService from '@/api/version';

const releaseTag = import.meta.env.VITE_SENTRY_RELEASE_TAG || "unknown"
const release = import.meta.env.VITE_SENTRY_RELEASE || "unknown"
const apiRelease = ref();

function loadData() {
    AppVersionService.getVersion().then((response) => {
        apiRelease.value = response.data;
    })
};

onMounted(() => {
    loadData();
})

</script>

<template>
    <v-footer border class="flex-grow-0">
        <v-row no-gutters>
            <v-col class="text-right" cols="12">
                <p :title="apiUrl"> {{ $appName }} <strong> FE </strong>{{ releaseTag }} ({{ release }}) <strong> BE
                    </strong>{{ apiRelease?.releaseTag || "unknown" }} ({{ apiRelease?.release || "unknown" }})</p>
                <p><strong>MyThinki</strong> 2024 — {{ new Date().getFullYear() }}</p>
            </v-col>
        </v-row>
    </v-footer>
</template>
