import { createRouter, createWebHistory } from 'vue-router'
import useLoginManager from '@/services/loginManager'
import { useSnackStore, type IShowSnack } from '@/stores/snack'
import { UserPrivilegeEnum } from '@/api/users'

const loginManager = useLoginManager()

declare module 'vue-router' {
  interface RouteMeta {
    title?: string
    requiredPrivileges?: string[][]
  }
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'dashboard',
      component: () => import('../views/DashboardView.vue'),
      meta: {
        title: 'Dashboard'
      }
    },
    {
      path: '/login',
      name: 'login',
      components: {
        fullscreen: () => import('../views/LoginView.vue')
      },
      meta: {
        title: 'Login',
        fullscreen: true
      }
    },
    {
      path: '/logout',
      name: 'logout',
      meta: {
        title: 'Logout'
      },
      redirect: '/login'
    },
    {
      path: '/users',
      name: 'users',
      meta: {
        title: 'Users',
        requiredPrivileges: [[UserPrivilegeEnum.SUPERADMIN]]
      },
      component: () => import('../views/UsersView.vue')
    },
    {
      path: '/users/:id/card/:tab?',
      name: 'user-card',
      props: true,
      meta: {
        title: 'User card',
        requiredPrivileges: [[UserPrivilegeEnum.SUPERADMIN]]
      },
      component: () => import('../views/UsersDetailView.vue')
    },
    {
      path: "/roles",
      name: "roles",
      meta: {
        title: "Roles",
        requiredPrivileges: [
          [UserPrivilegeEnum.SUPERADMIN,],
        ],
      },
      component: () => import("../views/RolesView.vue"),
    },
    {
      path: "/devices",
      name: "devices",
      meta: {
        title: "Devices",
        requiredPrivileges: [
          [UserPrivilegeEnum.SUPERADMIN,],
        ],
      },
      component: () => import("../views/DevicesView.vue"),
    },
  ]
})

router.beforeResolve(async (to) => {
  // check if user can even work in atlas
  // if logged and has no permission, show error
  await loginManager.init(router)

  if (loginManager.isLogged.value && !loginManager.hasPrivilege(UserPrivilegeEnum.SUPERADMIN)) {
    const snackStore = useSnackStore()
    snackStore.showSnack(<IShowSnack>{
      message: 'You have no access to Thinki Vortex',
      timeout: 3000,
      color: 'error'
    })

    // if not on login page
    if (!['login', 'oidc-callback'].includes(to.name?.toString() || '')) {
      // redirect to login page
      return { name: 'login' }
    }
  }

  // if user is not logged in, redirect to login page
  if (
    !loginManager.isLogged.value &&
    // Avoid an infinite redirect
    !['login', 'oidc-callback'].includes(to.name?.toString() || '')
  ) {
    return { name: 'login' }
  }
})

router.beforeResolve((to) => {
  // check if user has privileges required for the route
  const privs = to.meta.requiredPrivileges || []
  if (privs.length > 0) {
    if (
      !privs.some((andPrivileges) =>
        andPrivileges.every((privilege) => loginManager.hasPrivilege(privilege))
      )
    ) {
      return { name: 'dashboard' }
    }
  }
})

export default router
