<template>
    <v-btn icon @click="toggleTheme">
        <v-icon>mdi-theme-light-dark</v-icon>
    </v-btn>
</template>

<script setup lang="ts">
import { useTheme } from 'vuetify'
const theme = useTheme()

function toggleTheme() {
    theme.global.name.value = theme.global.current.value.dark ? 'thinkiLight' : 'thinkiDark'
}
</script>
